import RecentlyAdded from '../components/RecentlyAdded'
import PerformersCard from '../components/PerformersCard'
import MostPopularCard from '../components/MostPopularCard'
import GraphCard from '../components/GraphCard'
import TopMarketCap from '../components/TopMarketCapCryptos'
import TwitterEnbeb from '../components/TwitterEnbeb'
import { useTopFifty } from '../endpoints/index'
import FooterMobile from '../components/small/FooterMobile'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import { formatMoney, calculateReturn, calculateDrawdown } from '../helpers/index'
import Helmet from "react-helmet"

export default function HomePage(showModalType, setShowModalType) {

	const location = useLocation()

	const [totalReturn, setTotalReturn] = useState(0)
	const [drawdown, setDrawdown] = useState(0)
	const [fixedReturn, setFixedReturn] = useState(0)
	const [fixedDrawdown, setFixedDrawdown] = useState(0)
	const [emailConfirmed, setEmailConfirmed] = useState(location.state?.emailConfirmed || false)
	useEffect(() => {
		if (emailConfirmed === true) {
			// toast.success("Email Confirmed!")
			setEmailConfirmed(false)
			// alert("Email Confirmed! Please Sign In")
			showModalType.setShowModalType('CONFIRM_EMAIL')
		}
	})

	const calcTotalReturn = (currentPrice,initialPrice) => {
		setTotalReturn(calculateReturn(currentPrice, initialPrice))
	}
	const calcDrawdown = (values) => {
		setDrawdown(calculateDrawdown(values))
	}

	return(
		<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Bitformance: Track, Analyze, & Create Cryptocurrency Indexes</title>
			<link rel="canonical" href="https://www.bitformance.com" />
			<meta
			  name="description"
			  content="Track, analyze, and create cryptocurrency indexes on one platform, for free. Choose from over 500+ cryptocurrencies and compare performance metrics with ease."
			/>
		</Helmet>
			<div className="p-0 bg-main-lightGray space-y-4 relative sm:p-4">
			<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 pt-4">
				<RecentlyAdded />
				<PerformersCard />
				<MostPopularCard />
			</div>
			<div id="coinmarketcap-widget-marquee" coins="1,1027,825" currency="USD" theme="light" transparent="false" show-symbol-logo="true"></div>
			<GraphCard title="Bitformance Altcoin Index" hook={useTopFifty} calcTotalReturn={calcTotalReturn} calcDrawdown={calcDrawdown} />
			<div className="grid grid-cols-3 gap-4 xl:grid-cols-4">
				<div className="bg-main-white col-start-1 col-span-3 border border-main-inputBorder sm:rounded-2xl">
					<TopMarketCap />
				</div>
				<div className="bg-main-white rounded-2xl col-start-1 col-span-3 xl:col-start-4">
					<TwitterEnbeb />
				</div>
			</div>
			<div className='bg-white md:hidden'>
				<FooterMobile />
			</div>
		</div>
		</>
	)
}
