import { useParams, useNavigate } from 'react-router-dom'
import SocialIconsRow from '../components/small/SocialIconsRow'
import BFLoading from '../components/small/BFLoading'
import BFCryptoInfo from '../components/BFCryptoInfo'
import { baseUrl, useCoinBySymbol } from '../endpoints/index'
import { useState, useEffect } from 'react'
import BFCryptoImage from '../components/small/BFCryptoImage'
import FooterMobile from '../components/small/FooterMobile'
import BFPerformance from '../components/small/BFPerformance'
import Helmet from "react-helmet"
import { formatMoney, calculateReturn, calculateDrawdown } from '../helpers/index'

export default function SingleCoinPage() {
	const params = useParams()
	const { data, isLoading } = useCoinBySymbol(params.symbol)

	const [totalReturn, setTotalReturn] = useState(0)
	const [drawdown, setDrawdown] = useState(0)
	const [fixedReturn, setFixedReturn] = useState(0)
	const [fixedDrawdown, setFixedDrawdown] = useState(0)
	const calcTotalReturn = (currentPrice,initialPrice) => {
		setTotalReturn(calculateReturn(currentPrice, initialPrice))
	}
	const calcDrawdown = (values) => {
		setDrawdown(calculateDrawdown(values))
	}

	useEffect(() => {
		if (data) {
			setFixedReturn(calculateReturn(data.value || data.price, data.initial_value))
			setFixedDrawdown(calculateDrawdown(data.daily_graph_data.prices || data.daily_graph_data.price || []))
		}
	})

	const navigate = useNavigate()

	const compareRow = (e) => {
		e.stopPropagation()
		navigate(`/compare?id=${params.symbol}`)
	}

	const PanelOne = ({ id, name, description }) => (
		<div className="p-6 space-y-4 border bg-white xl:block col-span-3">
			<div className="flex flex-row items-center gap-2">
				<BFCryptoImage symbol={data.symbol} index={0} isLarge={true} />
				<h2 className="text-xl">{data.name}</h2>
			</div>
			<div className="flex flex-row gap-3 justify-between">
				<button onClick={(e) => compareRow(e)} className="px-6 py-1.5 w-full text-[15px] font-DM_Sans font-bold leading-normal tracking-normal rounded-lg shadow shadow-main-shadowBlue bg-main-lightGreen text-main-green">Compare</button>
			</div>
			<hr></hr>
			<div>
				<p className="p-1 text-[15px] font-DM_Sans flex flex-row"><strong>Sector</strong>: {data.tier_lvl_1}</p>
				<p className="p-1 text-[15px] font-DM_Sans flex flex-row"><strong>Industry</strong>: {data.tier_lvl_2 == 'N/A' ? '' : data.tier_lvl_2}</p>
				<p></p>
			</div>
			<p className="p-1 text-[15px] font-DM_Sans flex flex-row">{data.description}</p>
			<BFPerformance data={{ index: data }} totalReturn={totalReturn} drawdown={drawdown} fixedReturn={fixedReturn} fixedDrawdown={fixedDrawdown}/>
		</div>
	)

	if (isLoading) return <BFLoading />

	return (
		<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>
				{`${data.name} (${params.symbol}) Price, Market Cap, and Volume Data`}
			</title>
			<link rel="canonical" href={`https://www.bitformance.com${window.location.pathname}`} />
			<meta
				name="description"
				content={`View ${data.name} (${params.symbol}) price, market cap, and daily change on Bitformance.`}
			/>
		</Helmet>
		<div className="grid grid-cols-1 md:grid-cols-12 bg-main-lightGray">
			<PanelOne {...data.index} />

			<div className="col-span-9 p-4 space-y-4">
				<BFCryptoInfo data={{ index: data }} singlePage={true} calcTotalReturn={calcTotalReturn} calcDrawdown={calcDrawdown} totalReturn={totalReturn} drawdown={drawdown} fixedReturn={fixedReturn} fixedDrawdown={fixedDrawdown}/>
			</div>
			<div className='bg-white rounded-xl border md:hidden'>
				<FooterMobile />
			</div>
		</div>
		</>
	)
}
