import TopMarketCapCryptos from '../components/TopMarketCapCryptos'
import Helmet from "react-helmet"

export default function CoinsPage() {
	return (
		<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Explore Cryptocurrencies</title>
			<link rel="canonical" href="https://www.bitformance.com/coins" />
			<meta
			  name="description"
			  content="Browse over 500+ of the most popular cryptocurrencies and sort by market cap, volume, price, and more."
			/>
		</Helmet>
		<div className="p-4 pt-6 bg-main-lightGray sm:rounded-2xl">
			<TopMarketCapCryptos title="" />
		</div>
		</>
	)
}
