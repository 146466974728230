import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSectorIndexes } from '../endpoints/index';
import BFLoading from '../components/small/BFLoading';
import { formatMoney } from '../helpers/index';
import Helmet from "react-helmet"
import SectorBarChart from '../components/SectorBarChart';
import SectorPieChart from '../components/SectorPieChart';
import SectorHeatmap from '../components/SectorHeatmap';
import SectorMiniWidgets from '../components/SectorMiniWidgets'; // Import the new component

export default function SectorPage() {
  const navigate = useNavigate();
  const [sortField, setSortField] = useState('market_cap');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showIndexRows, setShowIndexRows] = useState([]);
  const [marketCapData, setMarketCapData] = useState([]);
  const [equalWeightData, setEqualWeightData] = useState([]);
  const { data, taxonomyStats, lastUpdated, isLoading, mutate } = useSectorIndexes('market_cap', 'asc');

  useEffect(() => {

	  if (data) {
		setShowIndexRows(data.map((datapoint) => datapoint._id));
		setMarketCapData(data.filter((index) => !index.name.includes('EQW')))
		setEqualWeightData(data.filter((index) => index.name.includes('EQW')))
	  }
  }, [data]);

  const getLastUpdatedString = (isoTimestamp) => {
	   const date = new Date(isoTimestamp);
	   const hours = date.getHours().toString().padStart(2, '0');
       const minutes = date.getMinutes().toString().padStart(2, '0');
       const seconds = date.getSeconds().toString().padStart(2, '0');
       const day = date.getDate().toString().padStart(2, '0');
       const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
       const year = date.getFullYear();

	   // Construct the desired format
	   return `Last Updated at ${hours}:${minutes}:${seconds} UTC on ${month}/${day}/${year}`;
  }

  const sortArrayByField = (array, field, order = 'asc') => {
	  return [...array].sort((a, b) => {
	    if (a[field] < b[field]) {
	      return order === 'asc' ? -1 : 1;
	    }
	    if (a[field] > b[field]) {
	      return order === 'asc' ? 1 : -1;
	    }
	    return 0;
	  });
	};

	const sortByNestedFieldInArray = (array, nestedField, order = 'asc') => {
	  return [...array].sort((a, b) => {
	    // Split the nested field string into parts
	    const [nestedObj, field] = nestedField.split('.');

	    // Extract the nested field values
	    const aValue = a[nestedObj][field];
	    const bValue = b[nestedObj][field];

	    if (aValue < bValue) {
	      return order === 'asc' ? -1 : 1;
	    }
	    if (aValue > bValue) {
	      return order === 'asc' ? 1 : -1;
	    }
	    return 0;
	  });
	};

  const sortBy = (array,label) => {
    if (!label) return;
	let so = 'desc'
	if (sortOrder === 'desc') {
		so = 'asc'
	}
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    setSortField(label);

	if (array === 'marketCapData') {
		const mc_arr = sortArrayByField(marketCapData, label, so)
		mc_arr.forEach(arr => {
			arr.subsectors = sortByNestedFieldInArray(arr.subsectors, `index.${label}`, so)
		})
		setMarketCapData(mc_arr)
	}
	if (array === 'equalWeightData') {
		const eq_arr = sortArrayByField(equalWeightData, label, so)
		eq_arr.forEach(arr => {
			arr.subsectors = sortByNestedFieldInArray(arr.subsectors, `index.${label}`, so)
		})
		setEqualWeightData(eq_arr)
	}
  };

  const rowClicked = (coin) => {
    navigate(`/indexes/browse/${coin._id.$oid}`);
  };

  const toggleIndex = (idx) => {
    setShowIndexRows((prevShowIndexRows) => {
      if (prevShowIndexRows.includes(idx)) {
        return prevShowIndexRows.filter((id) => id !== idx);
      } else {
        return [...prevShowIndexRows, idx];
      }
    });
  };

  const formatPercentage = (value) => {
    return value != null && !isNaN(value) ? `${value.toFixed(2)}%` : 'N/A';
  };

  const formatMoneySafe = (value) => {
    return value != null && !isNaN(value) ? formatMoney(value) : 'N/A';
  };

  const getSortIcon = (field) => {
    if (field !== sortField) return null;
    return sortOrder === 'asc' ? (
      <i className="fas fa-chevron-up ml-1"></i>
    ) : (
      <i className="fas fa-chevron-down ml-1"></i>
    );
  };

  const interpolateColor = (value, min, max, startColor, endColor, opacity) => {
    const ratio = (value - min) / (max - min);
    const r = Math.ceil(parseInt(startColor.substring(1, 3), 16) * (1 - ratio) + parseInt(endColor.substring(1, 3), 16) * ratio);
    const g = Math.ceil(parseInt(startColor.substring(3, 5), 16) * (1 - ratio) + parseInt(endColor.substring(3, 5), 16) * ratio);
    const b = Math.ceil(parseInt(startColor.substring(5, 7), 16) * (1 - ratio) + parseInt(endColor.substring(5, 7), 16) * ratio);
    return `rgba(${r},${g},${b},${opacity})`;
  };

  const getBackgroundColor = (value) => {
    const opacity = 0.5; // Adjust the opacity value as needed
    if (value > 10) return `rgba(87, 187, 138, ${opacity})`;
    if (value < -10) return `rgba(230, 124, 115, ${opacity})`;
    if (value === 0) return `rgba(255, 255, 255, ${opacity})`;
    if (value > 0 && value <= 10) {
      return interpolateColor(value, 0, 10, '#ffffff', '#57bb8a', opacity);
    }
    if (value < 0 && value >= -10) {
      return interpolateColor(value, -10, 0, '#e67c73', '#ffffff', opacity);
    }
  };

  const renderSubsectors = (subsectors) => {
    return subsectors.map(({ index }) => (
      <tr key={index._id.$oid} className="bg-white hover:bg-gray-50 border-b border-gray-200">
        <td className="py-1 px-2 w-1/12 pl-12">
          <a className="cursor-pointer hover:text-blue-500 hover:underline" onClick={() => {navigate(`/indexes/browse/${index._id.$oid}`)}}>{index.name}</a>
        </td>
		<td className="py-1 px-1 w-1/12 text-center">
          {index.constituents}
        </td>
        <td className="py-1 px-1 w-1/12">{formatMoneySafe(index.value)}</td>
        <td className="py-1 px-1 w-1/12">{formatMoneySafe(index.marketcap)}</td>
        <td className="py-1 px-1 w-1/12" style={{ backgroundColor: getBackgroundColor(index.changepct_24hour) }}>
          {formatPercentage(index.changepct_24hour)}
        </td>
        <td className="py-1 px-1 w-1/12" style={{ backgroundColor: getBackgroundColor(index.changepct_7d) }}>
          {formatPercentage(index.changepct_7d)}
        </td>
        <td className="py-1 px-1 w-1/12" style={{ backgroundColor: getBackgroundColor(index.changepct_1m) }}>
          {formatPercentage(index.changepct_1m)}
        </td>
        <td className="py-1 px-1 w-1/12" style={{ backgroundColor: getBackgroundColor(index.changepct_3m) }}>
          {formatPercentage(index.changepct_3m)}
        </td>
        <td className="py-1 px-1 w-1/12" style={{ backgroundColor: getBackgroundColor(index.changepct_6m) }}>
          {formatPercentage(index.changepct_6m)}
        </td>
        <td className="py-1 px-1 w-1/12" style={{ backgroundColor: getBackgroundColor(index.changepct_1y) }}>
          {formatPercentage(index.changepct_1y)}
        </td>
      </tr>
    ));
  };

  if (isLoading) return <BFLoading />;

  if (data) {
    // const marketCapData = data.filter((index) => !index.name.includes('EQW'));
    // const equalWeightData = data.filter((index) => index.name.includes('EQW'));
	const lastUpdatedString = getLastUpdatedString(lastUpdated)

    return (
      <>
		<Helmet>
				<meta charSet="utf-8" />
				<title>
				  Crypto Sector/Subsector Performance and Trends
				</title>
				  <link rel="canonical" href="https://www.bitformance.com/indexes/sectors" />
				<meta
				  name="description"
				  content={`Explore crypto sector and subsector performance data on Bitformance. Analyze market cap, trends, market dominance and more.`}
				/>
			</Helmet>
      <div className="grid grid-cols-1 lg:grid-cols-3 bg-main-lightGray p-4">
        <div className="md:col-span-2 p-2">
		<p id="timestamp" class="text-gray-400 text-xs italic">{lastUpdatedString}</p>
          {/* Market Cap Table */}
          <div className="overflow-x-auto bg-main-white justify-between mt-3 border-[1px] border-main-lightGrayBorder sm:rounded-2xl p-4">
            <h2 className="text-lg font-semibold mb-4">Market Cap Weighted</h2>
            <table className="min-w-full text-sm text-left mb-8">
              <thead className="table-header bg-gray-400 text-white uppercase text-sm leading-normal">
                <tr>
                  <th className="py-2 px-2 cursor-pointer " onClick={() => sortBy('marketCapData','name')}>
                    Sector {getSortIcon('name')}
                  </th>
				  <th className="py-2 px-2 cursor-pointer text-center" onClick={() => sortBy('marketCapData','constituents')}>
                    # Holdings {getSortIcon('constituents')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer " onClick={() => sortBy('marketCapData','value')}>
                    Value {getSortIcon('value')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer " onClick={() => sortBy('marketCapData','marketcap')}>
                    Market Cap {getSortIcon('market_cap')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer " onClick={() => sortBy('marketCapData','changepct_24hour')}>
                    24h % {getSortIcon('changepct_24hour')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer" onClick={() => sortBy('marketCapData','changepct_7d')}>
                    7d % {getSortIcon('changepct_7d')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer " onClick={() => sortBy('marketCapData','changepct_1m')}>
                    1m % {getSortIcon('changepct_1m')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer " onClick={() => sortBy('marketCapData','changepct_3m')}>
                    3m % {getSortIcon('changepct_3m')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer " onClick={() => sortBy('marketCapData','changepct_6m')}>
                    6m % {getSortIcon('changepct_6m')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer" onClick={() => sortBy('marketCapData','changepct_1y')}>
                    1y % {getSortIcon('changepct_1y')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {marketCapData.map((datapoint) => (
                  <React.Fragment key={datapoint._id}>
                    <tr
                      className="bg-gray-100 hover:bg-gray-50 border-b border-gray-300 cursor-pointer font-medium text-base"
                      onClick={() => toggleIndex(datapoint._id)}
                    >
                      <td className="py-1 px-2 w-1/12 whitespace-nowrap">
                        <button
                          className="mr-2 text-blue-500 text-lg font-bold"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleIndex(datapoint._id);
                          }}
                        >
                          {showIndexRows.includes(datapoint._id) ? '-' : '+'}
                        </button>
                        <a className="cursor-pointer hover:text-blue-500 hover:underline" onClick={() => {navigate(`/indexes/browse/${datapoint._id}`)}}>{datapoint.name}</a>
                      </td>
					  <td className="py-1 px-1 w-1/12 text-center">
                        {datapoint.constituents}
                      </td>
                      <td className="py-1 px-1 w-1/12">{formatMoneySafe(datapoint.value)}</td>
                      <td className="py-1 px-1 w-1/12">{formatMoneySafe(datapoint.marketcap)}</td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_24hour)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_7d)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_1m)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_3m)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_6m)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_1y)}
                      </td>
                    </tr>
                    {showIndexRows.includes(datapoint._id) && datapoint.subsectors && datapoint.subsectors.length > 0 && (
                            renderSubsectors(datapoint.subsectors)
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* Equal Weight Table */}
          <div className="overflow-x-auto bg-main-white justify-between mt-3 border-[1px] border-main-lightGrayBorder sm:rounded-2xl p-4">
            <h2 className="text-lg font-semibold mb-4">Equal Weight Weighted</h2>
            <table className="min-w-full text-sm text-left mb-8">
              <thead className="table-header bg-gray-400 text-white uppercase text-sm leading-normal">
                <tr>
                  <th className="py-2 px-2 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','name')}>
                    Sector {getSortIcon('name')}
                  </th>
				  <th className="py-2 px-2 cursor-pointer w-1/12 text-center" onClick={() => sortBy('equalWeightData','constituents')}>
                    # Holdings {getSortIcon('constituents')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','value')}>
                    Value {getSortIcon('value')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','marketcap')}>
                    Market Cap {getSortIcon('market_cap')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','changepct_24hour')}>
                    24h % {getSortIcon('changepct_24hour')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','changepct_7d')}>
                    7d % {getSortIcon('changepct_7d')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','changepct_1m')}>
                    1m % {getSortIcon('changepct_1m')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','changepct_3m')}>
                    3m % {getSortIcon('changepct_3m')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','changepct_6m')}>
                    6m % {getSortIcon('changepct_6m')}
                  </th>
                  <th className="py-2 px-1 cursor-pointer w-1/12" onClick={() => sortBy('equalWeightData','changepct_1y')}>
                    1y % {getSortIcon('changepct_1y')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {equalWeightData.map((datapoint) => (
                  <React.Fragment key={datapoint._id}>
                    <tr
                      className="bg-gray-100 hover:bg-gray-50 border-b border-gray-300 cursor-pointer font-medium text-base"
                      onClick={() => toggleIndex(datapoint._id)}
                    >
                      <td className="py-1 px-2 w-1/12 whitespace-nowrap">
                        <button
                          className="mr-2 text-blue-500 text-lg font-bold"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleIndex(datapoint._id);
                          }}
                        >
                          {showIndexRows.includes(datapoint._id) ? '-' : '+'}
                        </button>
                        <a className="cursor-pointer hover:text-blue-500 hover:underline" onClick={() => {navigate(`/indexes/browse/${datapoint._id}`)}}>{datapoint.name}</a>
                      </td>
					  <td className="py-1 px-2 w-1/12 text-center">
                        {datapoint.constituents}
                      </td>
                      <td className="py-1 px-1 w-1/12">{formatMoneySafe(datapoint.value)}</td>
                      <td className="py-1 px-1 w-1/12">{formatMoneySafe(datapoint.marketcap)}</td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_24hour)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_7d)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_1m)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_3m)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_6m)}
                      </td>
                      <td className="py-1 px-1 w-1/12">
                        {formatPercentage(datapoint.changepct_1y)}
                      </td>
                    </tr>
                    {showIndexRows.includes(datapoint._id) && datapoint.subsectors && datapoint.subsectors.length > 0 && (
						renderSubsectors(datapoint.subsectors)
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-4">
          <div className="flex flex-col justify-between h-full">
		  	<SectorMiniWidgets stats={taxonomyStats} /> {/* Add the SectorMiniWidgets component */}
            <SectorBarChart /> {/* Added the SectorBarChart component */}
            <SectorPieChart /> {/* Added the SectorPieChart component */}
            <SectorHeatmap /> {/* Added the SectorPieChart component */}
          </div>
        </div>
      </div>
      </>
    );
  }
  return null;
}
