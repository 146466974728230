import RecentlyAdded from '../components/RecentlyAdded'
import PerformersCard from '../components/PerformersCard'
import MostPopularCard from '../components/MostPopularCard'
import GraphCard from '../components/GraphCard'
import FooterMobile from '../components/small/FooterMobile'
import { useAltcoinSeason } from '../endpoints/index'
import { useState, useEffect } from 'react'
import Helmet from "react-helmet"
// import { formatMoney, calculateReturn, calculateDrawdown } from '../helpers/index'

export default function AltcoinSeasonPage(showModalType, setShowModalType) {

	const calcTotalReturn = (currentPrice,initialPrice) => {
		return 0
	}
	const calcDrawdown = (values) => {
		return 0
	}

	return(
		<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Altcoin Season Index - Bitformance</title>
			<link rel="canonical" href="https://www.bitformance.com/Altcoin-Season-Index" />
			<meta
			name="description"
			content="Is it altcoin season? Track the percentage of altcoins outperforming Bitcoin over 90 days with our Altcoin Season Index."
			/>
		</Helmet>
			<div className="p-0 bg-main-lightGray space-y-4 relative sm:p-4">
				<GraphCard title="Altcoin Season Index" hook={useAltcoinSeason} calcTotalReturn={calcTotalReturn} calcDrawdown={calcDrawdown} hide24h={true} isIndicator={true} />
			<div className="grid grid-cols-3 gap-4 xl:grid-cols-4">
			</div>
			<div className='bg-white md:hidden'>
				<FooterMobile />
			</div>
		</div>
		</>
	)
}
